@import '../../App.scss';
.safe-area {
  height: 80px;
}

.header {
  height: 80px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  z-index: 1;
  border-bottom: rgba(0, 0, 0, 0.55) 1px solid;
  a {
    color: $white;
    &:hover {
      color: black;
    }
  }

  .header-contents {
    width: 90%;
    .logo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: AirbnbCereal-Bold;
      .logo-image {
        width: 114px;
      }
      a {
        display: inline-block;
      }
    }
    .header-menus {
      background-color: $red;
      display: none;
      z-index: 100;
      &.open {
        display: block;
        width: 340px;
        position: absolute;
        right: 0;
        top: 0;
        padding-top: 110px;
        padding-left: 40px;
        padding-bottom: 80px;
      }
      .header-menu {
        .main-menu {
          margin-top: 40px;
          font-family: AirbnbCereal-Bold;
          font-size: 30px;
          font-weight: bold;
          color: $white;
          width: 260px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        }
        .sub-menus {
          margin-left: 10px;
          font-family: NanumSquareB;
          font-size: 16px;
          color: $white;
          display: none;
          &.open {
            display: block;
          }
          .sub-menu {
            margin-top: 26px;
          }
        }
      }
      .close-button {
        display: block;
        position: absolute;
        top: 19px;
        right: 24px;
        cursor: pointer;
        width: 30px;
        height: 30px;
        &:after {
          content: '\00d7';
          font-family: NanumSquareL;
          font-size: 30px;
        }
      }
    }
  }

}
