@import '../../App.scss';

.business-area {
  .swiper-button-next,
  .swiper-button-prev {
    color: #646464;
  }
  .swiper-scrollbar-drag {
    background: $red;
  }
}
