@import '../../App.scss';

.sns {
  position: relative;
  background-color: $white;
  margin: 0 auto;
  min-height: 800px;
  padding-top: 150px;
  text-align: center;

  .content {
    width: 550px;
    margin: 0 auto;
    h1 {
      font-family: AirbnbCereal-ExtraBold;
      font-size: 50px;
      font-weight: 800;
      margin-bottom: 20px;
      color: $black;
    }
    .discription {
      font-family: NanumSquareR;
      font-size: 20px;
      line-height: 1.8;
    }
  }
  .sns-images {
    margin: 0 auto;
    margin-top: 50px;
    width: 100vw;
    bottom: 0;
    // max-width: 1400px;
    .sns-image {
      width: calc(100vw / 5);
      height: 200px;
      outline: 1px solid black;
      float: left;
    }
  }
}
