@import '~antd/dist/antd.css';

.button-area {
    display: flex;
    justify-content: space-between;
}
.table {
    tbody {
        tr:hover {
            cursor: pointer;
        }
    }
}