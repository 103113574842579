@import '../../App.scss';

.footer {
  height: 254px;
  position: relative;
  background-color: $white;
  display: flex;
  justify-content: center;
  padding: 69px 20px 54px 20px;
  border-top: 1px solid rgba(203, 203, 203, 0.5);

  .content {
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80vw;
      max-width: 1200px;
      // min-width: 900px;
    }
    .logo {
      img {
        width: 106px;
      }
    }
    .address {
      margin-top: 25px;
      font-family: NanumSquareR;
      font-size: 12px;
      line-height: 1.67;
      .company-name {
        font-family: NanumSquareEB;
      }
    }
    .company-official {
      margin-top: 18px;
      opacity: 0.6;
      font-family: NanumSquareR;
      font-size: 12px;
      line-height: 2;
    }
    .icon {
      margin-left: 30px;
      &:hover {
        fill: $red;
      }
    }
  }
}
