@import '../../App.scss';

.vision {
  background-color: $black;
  min-height: 667px;
  .background {
    // width: 100%;
    svg {
      height: 100vh !important;
      width: auto !important;
    }
  }
  .content {
    position: absolute;
    color: $white;
    .top {
      height: 300px;
      overflow: hidden;
      .top-content {
        margin-top: 300px;
        word-break: break-all;
        .title1 {
          font-family: AirbnbCereal-Bold;
          font-size: 24px;
          color: $red;
        }
        .title2 {
          // margin-top: 12px;
          font-family: AirbnbCereal-Bold;
          font-size: 100px;
          color: $white;
        }
        .title3 {
          margin-top: -30px;
          // font-family: NanumSquareEB;
          font-size: 70px;
          color: $white;
        }
        &.rise-up {
          margin-top: 0;
          animation: rise-up $riseupAnimation forwards;
        }

        @keyframes rise-up {
          from {
            margin-top: 300px;
          }
          to {
            margin-top: 0px;
          }
        }
      }
    }
    .middle {
      margin-top: 30px;
      opacity: 0.6;
      font-family: NanumSquareR;
      font-size: 20px;
      line-height: 0.5;
      opacity: 0;
      &.fadein {
        animation: fadein $fadeinAnimation forwards;
        animation-delay: 0.5s;
      }
    }
    .bottom {
      margin-top: 110px;
      font-family: THEFACESHOP;
      font-size: 54px;
      white-space: nowrap;
      overflow: hidden;
      margin: 0 auto;
      width: 0;
      &.typing {
        animation: typing 2s steps(40, end) forwards;
        animation-delay: 1s;
      }
    }
    button.apply-button {
      margin-top: 39px;
      border: 0;
      width: 224px;
      height: 61px;
      margin: 39px 242px 0 240px;
      padding: 16px 38px 19px 44px;
      border-radius: 31px;
      background-color: $red;
      color: $white;
      opacity: 0;
      &:hover {
        color: $black;
        box-shadow: 0 4px 30px 0 rgba(237, 28, 36, 0.9);
      }
      span {
        font-family: NanumSquareB;
        font-size: 20px;
        line-height: 1.3;
      }
      &.fadein {
        animation: fadein $fadeinAnimation forwards;
        animation-delay: 2s;
      }
    }
  }

  @media (max-height: 899px), (max-width: 1199px) {
    .content {
      .middle {
        display: none;
      }
      .bottom {
        display: none;
      }
    }
    @media (orientation: portrait) {
      .content {
        width: 100%;
        overflow: hidden;
        .top {
          min-height: 250px;
          .top-content {
            margin-top: 450px;
            .title1 {
              // 24
              font-size: 40px;
            }
            .title2 {
              // 54
              font-size: 80px;
            }
            .title3 {
              // 42
              // font-size: 4vmin;
              font-size: 60px;
              span {
                display: block;
              }
            }

            &.rise-up {
              margin-top: 0;
              animation: rise-up $riseupAnimation forwards;
            }

            @keyframes rise-up {
              from {
                margin-top: 45vmin;
              }
              to {
                margin-top: 0px;
              }
            }
          }
        }
        .middle {
          display: none;
        }
        .bottom {
          display: none;
        }
        .bottom-button {
          width: 100%;
          button.apply-button {
            margin: 40px auto;
            &.fadein {
              animation: fadein $fadeinAnimation forwards;
              animation-delay: 1s;
            }
          }
        }
      }
    }

    @media (orientation: landscape) {
      .content {
        .top {
          .top-content {
            margin-top: 45vw;
            word-break: break-all;

            &.rise-up {
              margin-top: 0;
              animation: rise-up $riseupAnimation forwards;
            }

            @keyframes rise-up {
              from {
                margin-top: 45vw;
              }
              to {
                margin-top: 0px;
              }
            }
          }
        }
        button.apply-button {
          opacity: 0;
          &.fadein {
            animation: fadein $fadeinAnimation forwards;
            animation-delay: 1s;
          }
        }
      }
    }
  }

  @media (max-height: 666px) {
    .content {
      .top {
        .top-content {
          margin-top: 0;
        }
      }
      .middle {
        opacity: 1;
      }
      .bottom {
        width: 100%;
      }
      button.apply-button {
        opacity: 1;
      }
    }
  }
}
