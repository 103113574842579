@import '../../App.scss';

.interview {
  .contents {
    display: flex;
    justify-content: center;
    .content {
      display: flex;
      justify-content: center;
      margin: 100px 20px;
      flex-wrap: wrap;
      max-width: 1280px;
      .header {
        width: 80%;
        max-width: 1100px;
        display: flex;
        justify-content: center;
        img {
          // width: 300px;
          max-width: 1100px;
        }
        .right-content {
          margin-left: 69px;
          .title {
            margin-top: 105px;
            font-family: THEFACESHOP;
            font-size: 50px;
            line-height: 1.4;
            color: $white;
          }
          .name-container {
            margin-top: 75px;
            .name {
              font-family: NanumSquareEB;
              font-size: 36px;
              line-height: 0.89;
              color: #fff;
            }
            .job-title {
              font-family: NanumSquareB;
              font-size: 16px;
              color: #fff;
              margin-left: 18px;
            }
            .hash {
              margin-top: 5px;
              font-family: NanumSquareB;
              font-size: 14px;
              color: #fff;
            }
          }
        }
      }
      .interview-content {
        width: 80%;
        max-width: 1100px;
        margin-top: 100px;
        .question {
          margin-top: 67px;
          font-family: NanumSquareB;
          font-size: 22px;
          line-height: 1.45;
          color: $red;
        }
        .answer {
          margin-top: 25px;
          font-family: NanumSquareR;
          font-size: 18px;
          line-height: 1.78;
          color: $black;
          .question {
            margin-top: 67px;
            font-family: NanumSquareB;
            font-size: 22px;
            line-height: 1.45;
            color: $red;
          }
        }
      }
    }
  }

  .right-buttons {
    display: flex;
    justify-content: flex-end;
    padding: 30px;
    .footer-button {
      width: 90px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: solid 1px $black;
      font-family: NanumSquareB;
      font-size: 18px;
      line-height: 1.56;
      color: $black;
      cursor: pointer;
    }
  }

  @media (max-width: 900px) {
    .contents {
      .content {
        max-width: 90vw;
        margin: 30px 10px;
        .header {
          max-width: 90vw;
          img {
            max-width: 90vw;
          }
          .right-content {
            .title {
              margin-top: 30px;
              font-family: THEFACESHOP;
              font-size: 50px;
              line-height: 1.4;
              color: $white;
            }
            .name-container {
              margin-top: 75px;
              .name {
                font-family: NanumSquareEB;
                font-size: 36px;
                line-height: 0.89;
                color: #fff;
              }
              .job-title {
                font-family: NanumSquareB;
                font-size: 16px;
                color: #fff;
                margin-left: 18px;
              }
              .hash {
                margin-top: 5px;
                font-family: NanumSquareB;
                font-size: 14px;
                color: #fff;
              }
            }
          }
        }
        .interview-content {
          width: 90vw;
          .question {
            margin-top: 67px;
            font-family: NanumSquareB;
            font-size: 22px;
            line-height: 1.45;
            color: $red;
          }
          .answer {
            margin-top: 25px;
            font-family: NanumSquareR;
            font-size: 18px;
            line-height: 1.78;
            color: $black;
            .question {
              margin-top: 67px;
              font-family: NanumSquareB;
              font-size: 22px;
              line-height: 1.45;
              color: $red;
            }
          }
        }
      }
    }
  }
}
