@import '../../App.scss';

.key-members {
  background-color: $white;
  min-height: 850px;
  h1.top {
    font-family: AirbnbCereal-ExtraBold;
    font-size: 50px;
    font-weight: 800;
    margin-bottom: 16px;
    color: $black;
    height: 80px;
    overflow: hidden;
    .top-content {
      margin-top: 80px;
      &.rise-up {
        animation: rise-up $riseupAnimation forwards;
      }
    }
    @keyframes rise-up {
      from {
        margin-top: 80px;
      }
      to {
        margin-top: 0px;
      }
    }
  }
  .content {
    opacity: 0;
    &.fadein {
      animation: fadein $fadeinAnimation forwards;
      animation-delay: 0.5s;
    }
    .discription {
      font-family: NanumSquareR;
      font-size: 20px;
      line-height: 1.8;
    }
    .members {
      margin-top: 50px;
      // margin-top: min(50px, );
      width: 100vw;
      // display: flex;
      // justify-content: center;

      // .member {
      //   height: 420px;
      //   width: 366px;
      //   outline: 1px solid;
      // }

      opacity: 0;
      &.fadein {
        animation: fadein $fadeinAnimation forwards;
        animation-delay: 1s;
      }
      .member {
        // height: 252px;
        // width: 210px;
        // outline: 1px solid;
        // margin: 0 30px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // height: 650px;
        img {
          width: 366px;
        }
        .name-area {
          margin-top: 35px;
          .member-name {
            font-family: NanumSquareEB;
            font-size: 30px;
            line-height: 0.87;
            color: #000;
          }
          .member-title {
            margin-left: 7px;
            font-family: NanumSquareB;
            font-size: 18px;
            line-height: 1.44;
            color: $red;
          }
        }
        .english-name {
          margin-top: 12px;
          opacity: 0.5;
          font-family: AirbnbCerealApp;
          font-size: 20px;
          line-height: 1.3;
        }
        .member-discription {
          margin-top: 35px;
          height: 90px;
          font-family: NanumSquareR;
          font-size: 20px;
          line-height: 1.5;
          color: #ed1c24;
        }
      }
    }
  }

  @media (max-height: 850px), (max-width: 1199px) {
    .discription {
      display: none;
    }
  }
  @media (max-height: 850px) {
    .content {
      .members {
        margin-top: 2vmin;
      }
    }
    @media (orientation: portrait) {
      min-height: auto;
      .content {
        height: 80vh;
        overflow: hidden;
        .members {
          margin-top: auto;
          .member {
            height: 80vh;
            // margin: 0 auto;
            .memeber-introduction {
              background: linear-gradient(rgba( 255, 255, 255, 0 ), rgba( 255, 255, 255, 1 ));
              position: absolute;
              bottom: 3vh;
              width: 366px;
              .memeber-introduction-contents {
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
  }
  @media (max-height: 667px) {
    h1.top {
      .top-content {
        margin-top: 0;
      }
    }
    .content {
      opacity: 1;
      .members {
        opacity: 1;
      }
    }
  }
}
