@import '../../App.scss';

.representative-greeting {
  .content {
    padding: 20px;
    margin-top: 136px;
    display: flex;
    justify-content: center;
    margin-bottom: 241px;
    .left {
      margin-right: 44px;
      display: flex;
      align-items: center;
      img {
        width: 342px;
      }
    }
    .right {
      margin-left: 44px;
      width: 704px;
      h1 {
        font-family: NanumSquareEB;
        font-size: 34px;
        margin-bottom: 107px;
        .sub-title {
          margin-left: 17px;
          font-family: NanumSquareR;
          font-size: 18px;
          line-height: 1.78;
          color: $red;
        }
      }
      h2 {
        margin-top: 70px;
        font-family: THEFACESHOP;
        font-size: 36px;
        line-height: 0.89;
        color: $red;
      }
      section {
        margin-top: 10px;
        font-family: NanumSquareR;
        font-size: 20px;
        line-height: 1.6;
      }
      .sign-container {
        margin-top: 60px;
        font-family: NanumSquareEB;
        font-size: 20px;
        line-height: 1.6;
        text-align: right;
        .sign {
          margin-left: 13px;
          padding-top: 13px;
          font-family: THEFACESHOP;
          font-size: 60px;
          line-height: 0.53;
        }
      }
    }
  }

  @media (max-width: 880px) {
    .content {
      padding: 0;
      margin-top: 30px;
      display: block;
      margin-bottom: 241px;
      .left {
        margin-right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 342px;
        }
      }
      .right {
        margin: 44px auto;
        width: 90vw;
        text-align: center;
        h1 {
          .sub-title {
            margin-left: 0;
            display: block;
          }
        }
        .sign-container {
          .sign {
            display: block;
          }
        }
      }
    }
  }
}
