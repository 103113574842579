@import '../../App.scss';

.ant-pagination-item,
.ant-pagination-item-link {
  border: none !important;
}
.ant-pagination-item-active a {
  color: $white !important;
}
.ant-pagination-item-active {
  background-color: $black !important;
  border-radius: 16px !important;
}
