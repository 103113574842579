@import '../../App.scss';

.newsletter {
  .content {
    display: flex;
    justify-content: center;
    .container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      max-width: 1280px;
      margin: 100px 20px;

      .card {
        width: 319px;
        margin: 57px 37px;

        img {
          width: 100%;
        }
        .year {
          font-family: AirbnbCerealApp;
          font-size: 30px;
          font-weight: bold;
          line-height: 0.93;
          color: #000;
          padding-bottom: 20px;
          border-bottom: 1px solid $black;
        }
        .title {
          margin-top: 18px;
          font-family: NanumSquareB;
          font-size: 20px;
        }
        .card-image-container {
          width: 304px;
          height: 415px;
          margin-top: 34px;
          img {
            box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.15);
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}
