@import '../../App.scss';

.intro {
  background-color: $black2;
  color: $white;
  min-height: 667px;
  video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
  .content {
    margin-top: 80px;
    position: absolute;
    .logo-container {
      height: 116px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      .logo-img {
        width: 512px;
        // height: 100px;
        margin-top: 116px;
        &.rise-up {
          animation: rise-up $riseupAnimation forwards;
        }
        &.logo-red {
          background-image: url('/static/images/logo-first-red@3x.png');
          background-size: cover;
          opacity: 1;
        }
        &.logo-white {
          background-image: url('/static/images/logo-first-wh@3x.png');
          background-size: cover;
          opacity: 0.7;
        }
      }
      @keyframes rise-up {
        from {
          margin-top: 116px;
        }
        to {
          margin-top: 0px;
        }
      }
    }
    h1 {
      font-family: NanumSquareEB;
      font-size: 100px;
      font-weight: 700;
      color: $white;
    }
    h2.intro-discription {
      margin-top: 40px;
      font-family: THEFACESHOP;
      font-size: 90px;
      color: $white;
      // width: 0;
      // border-right: 1px solid $white;
      white-space: nowrap;
      overflow: hidden;
      margin: 0 auto;
      width: 0;
      &.typing {
        animation: typing 1s steps(40, end) forwards;
        animation-delay: 0.5s;
      }
    }
    .middle {
      margin-top: 100px;
      font-family: NanumSquareB;
      font-size: 30px;
      line-height: 0.67;
      color: $white;

      opacity: 0;
      &.fadein {
        animation: fadein $fadeinAnimation forwards;
        animation-delay: 1s;
      }
    }
    .bottom {
      font-family: NanumSquareR;
      margin-top: 30px;
      font-size: 22px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.5;
      letter-spacing: normal;

      opacity: 0;
      &.fadein {
        animation: fadein $fadeinAnimation forwards;
        animation-delay: 1.5s;
      }
    }
    .scroll-line-container {
      height: 50px;
    }
    .scroll-line {
      width: 1px;
      height: 50px;
      margin: 81px auto 0;
      opacity: 0.5;
      border: solid 1px $white;
      background-color: $white2;
      height: 0;
      &.scroll {
        animation: scroll 1.5s forwards;
        animation-iteration-count: infinite;
        // animation-delay: 2s;
      }
      @keyframes scroll {
        from {
          height: 1px;
        }
        to {
          height: 50px;
        }
      }
    }
    .mouse {
      width: 28px;
      height: 40px;
      margin: 10px auto 0;
      border-radius: 21px;
      border: solid 1px $white;

      .mouse-wheel {
        width: 4px;
        height: 7px;
        margin: 7px auto;
        border-radius: 3px;
        border: solid 1px $white;
      }
    }
    .scroll-text {
      margin: 4px 0 0;
      font-family: AirbnbCereal-Medium;
      font-size: 12px;
      line-height: 2.17;
      color: $white;
    }
    // @media screen and (max-height: 899px) {
    //   .logo-container {
    //   }
    // }
  }
  @media (max-height: 899px), (max-width: 1199px) {
    .content {
      .middle {
        display: none;
      }
      .bottom {
        display: none;
      }
      .logo-container {
        max-width: 100%;
        overflow: hidden;
     
        .logo-img {
          width: 80vmin;
        }
      }
      h2.intro-discription {
        margin-top: 30px;
        width: 100%;
        font-size: 10vmin;
      }
    }

    @media (orientation: portrait) {
      padding-top: 120px;
      max-width: 100%;
      overflow: hidden;
      .content {
        .logo-container {
          width: 80vmin;
          height: 15.439vmin;
          .logo-img {
            object-fit: cover;
            // max-width: 512px;
          }
        }
      }
    }
    @media (orientation: landscape) {
      .content {
        .logo-container {
          .logo-img {
            animation: rise-up $riseupAnimation forwards;
          }
          @keyframes rise-up {
            from {
              margin-top: 116px;
            }
            to {
              margin-top: 0px;
            }
          }
        }
        h2.intro-discription {
          animation: typing 1s steps(40, end) forwards;
          animation-delay: 0.5s;
        }
        .scroll-line {
          animation: scroll 1.5s forwards;
          animation-iteration-count: infinite;
          @keyframes scroll {
            from {
              height: 1px;
            }
            to {
              height: 50px;
            }
          }
        }
      }
    }
  }
}
