@import '../../App.scss';

.interviews {
  .contents {
    display: flex;
    justify-content: center;
    .content {
      display: flex;
      justify-content: center;
      margin: 120px 20px;
      flex-wrap: wrap;
      max-width: 1280px;
      // .content {
      .card {
        width: 520px;
        height: 300px;
        margin: 0 20px 64px;
        display: flex;
        border-radius: 10px;
        box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.15);
        &:hover {
          transform: scale(1.08, 1.08);
          transition-duration: 0.3s;
        }
        .left {
          .img {
            border-radius: 10px 0 0 10px;
            height: 300px;
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          align-content: center;
          justify-content: space-between;
          padding: 52px 36px;
          h3 {
            font-family: NanumSquareEB;
            font-size: 22px;
            line-height: 1.45;
          }
          .sub-title {
            opacity: 0.6;
            font-family: NanumSquareR;
            font-size: 16px;
            line-height: 1.75;
            margin-top: 20px;
          }
          .name {
            // position: absolute;
            // bottom: 10px;
            opacity: 0.7;
            font-family: NanumSquareEB;
            font-size: 24px;
            color: $black;
            .job-title {
              margin-left: 8px;
              font-family: NanumSquareB;
              font-size: 16px;
              color: $red;
            }
          }
        }
      }
      // }
    }
  }

  @media (max-width: 550px) {
    .contents {
      margin-top: 20px;
      .content {
        margin: 10px 0;
        .card {
          height: 220px;
          max-width: 90vw;
          .left {
            .img {
              height: 220px;
            }
          }
          .right {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px;
            h3 {
              font-size: 5vw;
            }
            .sub-title {
              opacity: 0.6;
              font-size: 4vw;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
