@import '../../App.scss';

.portfolio {
  .content {
    display: flex;
    justify-content: center;
    .container {
      max-width: 944px;
      margin: 0 20px;
      .sub-title {
        margin-top: 100px;
        font-family: NanumSquareR;
        font-size: 20px;
        color: $red;
      }
      h1 {
        margin-top: 28px;
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid $black;
        padding-bottom: 33px;
        .title {
          font-family: NanumSquareEB;
          font-size: 32px;
          line-height: 1;
          color: $black;
        }
        .date {
          opacity: 0.6;
          font-family: NanumSquareR;
          font-size: 20px;
          line-height: 1.4;
          text-align: right;
          color: $black;
        }
      }
      .main-image {
        margin-top: 65px;
        // width: 944px;
        width: 100%;
      }
      .content-body {
        margin-top: 57px;
        font-family: NanumSquareR;
        font-size: 18px;
        line-height: 1.78;
        color: $black;
        padding-bottom: 74px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      }
      .content-footer {
        margin-top: 20px;
        margin-bottom: 200px;
        display: flex;
        justify-content: space-between;
        .left-buttons {
          display: flex;
          .footer-button {
            width: 44px;
            height: 44px;
            opacity: 0.5;
          }
        }
        .right-buttons {
          .footer-button {
            width: 90px;
            height: 44px;
          }
        }
        .footer-button {
          display: flex;
          justify-content: center;
          align-items: center;
          border: solid 1px $black;
          font-family: NanumSquareB;
          font-size: 18px;
          line-height: 1.56;
          color: $black;
          cursor: pointer;
        }
      }
    }
  }
}
