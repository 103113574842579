@import '../../App.scss';

.our-numbers {
  background-color: $black;
  color: $white;
  min-height: 667px;
  h1.top {
    font-family: AirbnbCereal-ExtraBold;
    font-size: 50px;
    font-weight: 800;
    color: $white;
    height: 80px;
    overflow: hidden;
    .top-content {
      margin-top: 80px;
      &.rise-up {
        animation: rise-up $riseupAnimation forwards;
      }
    }
    @keyframes rise-up {
      from {
        margin-top: 80px;
      }
      to {
        margin-top: 0px;
      }
    }
  }
  .discription {
    margin-top: 16px;
    font-family: NanumSquareR;
    font-size: 20px;
    line-height: 0.7;
    opacity: 0;
    &.fadein {
      animation: fadein $fadeinAnimation forwards;
      animation-delay: 0.5s;
    }
  }
  .content {
    margin-top: 158px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100vw;
    max-width: 1400px;

    .card {
      width: 320px;
      // margin: 0 120px;
      opacity: 0;
      &.card1.fadein {
        animation: fadein $fadeinAnimation forwards;
        animation-delay: 0.7s;
      }
      &.card2.fadein {
        animation: fadein $fadeinAnimation forwards;
        animation-delay: 0.7s;
      }
      &.card3.fadein {
        animation: fadein $fadeinAnimation forwards;
        animation-delay: 0.7s;
      }
      .number {
        color: $red;
        font-family: AirbnbCereal-ExtraBold;
        font-size: 125px;
        font-weight: 800;
      }
      .measure {
        margin-left: 20px;
        font-family: NanumSquareEB;
        font-size: 30px;
        line-height: 0.8;
        color: #ed1c24;
      }
      .card-discription {
        font-family: NanumSquareB;
        font-size: 28px;
        color: $white;
      }
    }
  }

  @media (max-height: 667px), (max-width: 1199px) {
    .discription {
      display: none;
    }
    @media (orientation: portrait) {
      .content {
        margin-top: 5vh;
        display: block;
        .card {
          width: 90vw;
          margin: 0 auto;
          .number {
            font-size: 20vw;  // 125px
          }
          .measure {
            font-size: 5vw;  // 30
          }
          .card-discription {
            font-size: 4vw;  // 28
          }
        }
      }
    }
  }
  
  @media (max-height: 666px) {
    h1.top {
      .top-content {
        margin-top: 0;
      }
    }
    .discription {
      opacity: 1;
    }
    .content {
      margin-top: 0;
      .card {
        opacity: 1;
        .number {
          // font-size: 125px;
          font-size: 20vmin;
        }
        .measure {
          // font-size: 30px;
          font-size: 5vmin;
        }
        .card-discription {
          // font-size: 28px;
          font-size: 4vmin;
        }
      }
    }
  }
}
