@import '../../App.scss';

.members {
  position: relative;
  .contents {
    margin: 120px auto 100px auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1280px;
    .member {
      position: relative;
      max-width: 220px;
      max-height: 299px;
      overflow: hidden;
      .member-info {
        display: none;
      }
      img {
        max-width: 220px;
      }
      .img-grey {
        display: block;
      }
      .img-active {
        display: none;
      }

      &:hover {
        img {
          cursor: pointer;
        }
        .img-grey {
          display: none;
        }
        .img-active {
          display: block;
        }
        .member-info {
          display: block;
          position: absolute;
          bottom: 0;
          width: 100%;
          opacity: 0.7;
          background-color: $white;
          padding: 22px 24px;
          .name {
            font-family: NanumSquareEB;
            font-size: 24px;
            color: $black;
          }
          .title {
            font-family: NanumSquareB;
            font-size: 16px;
            color: #ed1c24;
            margin-left: 8px;
          }
        }
      }
    }
  }
}

.modal {
  .modal-content {
    display: flex;

    .modal-img-container {
      display: flex;
      justify-content: center;
      align-items: center;
      .modal-img {
        height: 500px;
      }
    }
    .modal-content-right {
      width: calc(800px - 366px);
      padding: 33px 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      .close-button {
        position: absolute;
        top: 20px;
        right: 33px;
        &:hover {
          cursor: pointer;
        }
        &:after {
          content: '\00d7';
          font-family: NanumSquareL;
          font-size: 30px;
          color: $black;
        }
      }
      .member-name-title {
        margin-top: 20px;
        .member-name {
          font-family: NanumSquareEB;
          font-size: 36px;
          color: $black;
        }
        .member-title {
          font-family: NanumSquareR;
          font-size: 20px;
          color: $red;
          margin-left: 12px;
        }
      }
      .introduction {
        margin-top: 25px;
        font-family: NanumSquareB;
        font-size: 16px;
        color: #424242;
      }
      .discription {
        margin-top: 65px;
        font-family: NanumSquareL;
        font-size: 16px;
        line-height: 1.5;
        color: #424242;
        b {
          font-family: NanumSquareB;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .members {
    position: relative;
    .contents {
      margin: 120px auto 100px auto;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      // max-width: 1280px;
      width: 90vw;
      .member {
        position: relative;
        width: 30vw;
        height: 40vw;
        .member-info {
          display: none;
        }
        img {
          width: 30vw;
        }
        .img-grey {
          display: block;
        }
        .img-active {
          display: none;
        }

        &:hover {
          img {
            cursor: pointer;
          }
          .img-grey {
            display: none;
          }
          .img-active {
            display: block;
          }
          .member-info {
            display: block;
            // position: absolute;
            bottom: 0;
            width: 100%;
            opacity: 0.7;
            background-color: $white;
            padding: 4vw;
            .name {
              font-family: NanumSquareEB;
              font-size: 4vw;
              color: $black;
            }
            .title {
              font-family: NanumSquareB;
              font-size: 16px;
              color: #ed1c24;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }

  .modal {
    .modal-content {
      display: block;
      .modal-img-container {
        display: flex;
        justify-content: center;
        .modal-img {
          max-height: 45vh;
          margin: 0 auto;
        }
      }
      .modal-content-right {
        width: 90vw;
        // height: 45vh;
        overflow: auto;
        padding: 10px;
        margin: 0 auto;
        .close-button {
          position: absolute;
          right: 20px;
          top: 10px;
        }
        .modal-right-content {
          height: 45vh;
        }
        .member-name-title {
          margin-top: 10px;
          .member-name {
            font-family: NanumSquareEB;
            font-size: 6vw;
            color: $black;
          }
          .member-title {
            font-family: NanumSquareR;
            font-size: 5vw;
            color: $red;
            margin-left: 12px;
          }
        }
        .introduction {
          margin-top: 15px;
          font-family: NanumSquareB;
          font-size: 4vw;
          color: #424242;
        }
        .discription {
          margin-top: 20px;
          font-family: NanumSquareL;
          font-size: 4vw;
          line-height: 1.5;
          color: #424242;
        }
      }
    }
  }
}
@media (max-height: 500px) {
  .modal {
    .modal-content {
      display: flex;
      .modal-img-container {
        .modal-img {
          max-height: 90vh;
        }
      }
      .modal-content-right {
        width: 60vw;
        max-height: 90vh;
        overflow: auto;
        // margin-top: -20px;
        padding: 20px 5%;
        .member-name-title {
          .member-name {
            font-size: 6vh;
          }
          .member-title {
            font-family: NanumSquareR;
            font-size: 5vh;
            margin-left: 12px;
          }
        }
        .introduction {
          margin-top: 15px;
          font-size: 4vh;
        }
        .discription {
          margin-top: 20px;
          font-size: 4vh;
        }
      }
    }
  }
}
