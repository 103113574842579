@import '../../App.scss';

.inquiry {
  .content {
    display: flex;
    justify-content: center;
    .container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      max-width: 1280px;
      margin: 100px 20px;
      @media screen and (min-width: calc(382px + 20px * 2)) {
        width: calc(382px);
      }
      @media screen and (min-width: calc(382px * 2 + 20px * 2)) {
        width: calc(382px * 2);
      }
      @media screen and (min-width: calc(382px * 3 + 20px * 2)) {
        width: calc(382px * 3);
      }

      .card {
        width: 340px;
        margin: 44px 21px;
        border-radius: 10px;
        box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.15);
        background-color: #fff;
        overflow: hidden;
        float: left;

        &:hover {
          transform: scale(1.05, 1.05);
          transition-duration: 0.3s;
        }
        .image {
          height: 227px;
          background-size: cover;
        }
        .card-container {
          margin: 40px 20px;
          justify-content: start;
          h1 {
            font-family: NanumSquareEB;
            font-size: 22px;
            line-height: 1.45;
            color: #000;
            height: 64px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .card-content {
            margin-top: 24px;
            opacity: 0.6;
            color: #000;
            height: 77px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            * {
              font-family: NanumSquareR !important;
              font-size: 16px !important;
              line-height: 1.75 !important;
            }
          }

          .date {
            margin-top: 33px;
            opacity: 0.6;
            font-family: NanumSquareR;
            font-size: 16px;
            line-height: 1.75;
            color: #000;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }
  }
}
