@import '~antd/dist/antd.css';

.login {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .login-form {
        width: 350px;
        max-width: 80vw;
    }
}