@import '../../App.scss';
.sub-header {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  text-align: center;
  margin: 0 20px;
  .title {
    font-family: NanumSquareEB;
    font-size: 40px;
  }
  .discription {
    margin-top: 17px;
    font-family: NanumSquareR;
    font-size: 20px;
    line-height: 1.25;
  }
  
  @media (max-width: 420px) {
    .discription {
      span {
        display: block;
      }
    }
  }
}
