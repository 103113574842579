@import '../../App.scss';

.header {
  background-image: url('/static/images/sub-img-1.png');
}

.vision-ci {
  .contents {
    display: flex;
    justify-content: center;
    text-align: center;
    .content {
      width: 95%;
      h1 {
        margin-top: 106px;
        font-family: NanumSquareEB;
        font-size: 36px;
        line-height: 1.67;
      }
      h2 {
        margin-top: 43px;
        font-family: NanumSquareR;
        font-size: 20px;
        line-height: 1.6;
      }
      .line {
        width: 1px;
        height: 150px;
        background-color: $red;
        margin: 40px auto 58px;
      }
      .ci {
        margin-top: 40px;
        img {
          width: 100%;
          max-width: 1280px;
        }
      }
    }
  }
}
