@import '../../App.scss';

.header-menus {
  header {
    position: absolute;
  }
}
.main {
  .safe-area {
    display: none;
  }
}

.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  // outline: 2px solid red;
  text-align: center;
  // min-width: 1280px;
  // min-height: 1000px;
  overflow: hidden;
}

// @media screen and (max-height: 769px) {
//   /* 모바일에서 사용될 스타일을 여기에 작성합니다. */
//   .container {
//     min-height: 800px;
//   }
// }

// @media screen and (min-width: 1199px) {
//   /* 모바일에서 사용될 스타일을 여기에 작성합니다. */
//   .container {
//     max-width: 100%;
//     overflow: hidden;
//   }
// }

// @media screen and (min-height: 900px) {
//   .container {
//     height: 100vh;
//   }
// }

// @media screen and (min-height: 667px) {
//   .container {
//     height: 100vh;
//   }
// }

// @media screen and (max-height: 667px) {
//   .container {
//     padding: 80px 20px;
//   }
// }
