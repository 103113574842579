@import '../../App.scss';

.introduction {
  margin: 0 auto;
  .intro {
    margin: 0 20px;
    text-align: center;
    .title {
      margin-top: 106px;
      font-family: NanumSquareEB;
      font-size: 36px;
      .content {
        font-family: NanumSquareR;
        font-size: 20px;
        line-height: 1.6;
        margin: 0 20px;
        h1 {
          color: $white;
        }
      }
    }
    .p1 {
      margin-top: 43px;
    }
    .p2 {
      margin-top: 17px;
    }
    .p3 {
      margin-top: 17px;
    }
  }
  .speech-bubble {
    position: relative;
    margin: 50px auto;
    width: 356px;
    height: 80px;
    padding: 28px 30px 27px 49px;
    border-radius: 40px;
    background-color: $red;
    font-family: NanumSquareB;
    font-size: 23px;
    line-height: 1.09;
    color: $white;
  }
  .speech-bubble:after {
    content: '';
    position: absolute;
    height: 50px;
    width: 50px;
    border-radius: 15px;
    z-index: -1;
    background: #fff;
    // margin-bottom: -20px;
    bottom: -20px;
    left: 181px;
  }
  .speech-bubble:before {
    content: '';
    position: absolute;
    height: 50px;
    width: 50px;
    border-radius: 25px;
    z-index: -1;
    background: $red;
    // margin-bottom: -15px;
    // bottom: 15px;
    bottom: -15px;
    left: 166px;
  }
  .three-principles {
    margin: 0 20px;
    margin-top: 130px;
    display: flex;
    justify-content: center;
    .card {
      text-align: left;
      margin: 0 48px;
      // max-width: 304px;
      width: 32%;
      h1 {
        margin-top: 12px;
        font-family: NanumSquareEB;
        font-size: 30px;
      }
      h2 {
        font-family: AirbnbCereal-Medium;
        font-size: 16px;
        color: red;
      }
      .content {
        margin-top: 20px;
        padding-top: 19px;
        font-family: NanumSquareR;
        font-size: 16px;
        line-height: 1.75;
        border-top: 1px solid $black;
      }
    }
  }
  // @media screen and (max-width: 712px) {
  //   .three-principles {
  //     .card {
  //     }
  //   }
  // }
  @media screen and (max-width: 855px) {
    .three-principles {
      display: block;
      .card {
        margin: 0 auto;
        margin-top: 40px;
        width: 100%;
      }
    }
  }

  .contents {
    position: relative;
    margin-top: 264px;
    background-color: $black3;

    .images {
      position: absolute;
      top: -118px;
      right: -270px;
      display: flex;
    }
    .content {
      padding-top: 307px;
      color: $white;
      display: flex;
      justify-content: space-around;
      margin: 0 5%;
      .left {
        // margin-right: 163px;
        h1 {
          font-family: 'NanumSquareEB';
          font-size: 36px;
          line-height: 1.67;
          color: $white;
          word-break: keep-all;
        }
      }
      .right {
        text-align: left;
        max-width: 624px;
        margin-left: 40px;
        .card {
          margin-bottom: 84px;
          h2 {
            font-family: NanumSquareEB;
            font-size: 17px;
            color: $white;
          }
          .right-content {
            margin-top: 32px;
            font-family: NanumSquareL;
            font-size: 15px;
            line-height: 1.8;
          }
          .hashes {
            margin-top: 16px;
            font-family: NanumSquareR;
            font-size: 14px;
            line-height: 1.79;
            color: $red;
          }
        }
      }
    }
    @media screen and (max-width: 572px) {
      .content {
        display: block;
        padding-bottom: 84px;
        .right {
          margin-top: 84px;
          margin-left: 0;
        }
      }
    }
  }
  
}
