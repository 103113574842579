@import '~antd/dist/antd.css';

#components-layout-demo-top-side .logo {
  float: left;
  width: 120px;
  height: 31px;
  // margin: 16px 24px 16px 0;
  // background: rgba(255, 255, 255, 0.3);
  
  .site-layout-background {
    background: #fff;
  }
  img {
    width: 114px;
  }
}

// .ant-row-rtl #components-layout-demo-top-side .logo {
//   float: right;
//   margin: 16px 0 16px 24px;
// }

