@import '../../App.scss';

.insight-detail {
  .content {
    display: flex;
    justify-content: center;
    .container {
      width: 80%;
      max-width: 1280px;
      margin-top: 100px;
      .header {
        font-family: NanumSquareR;
        font-size: 18px;
        line-height: 1.56;
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid $black;
        padding: 16px 0;
        .title {
          font-family: NanumSquareB;
        }
      }
      .img-container {
        margin-top: 65px;
        .img {
          width: 100%;
        }
      }
      .detail {
        padding: 62px 0px;
        img {
          max-width: 100%;
        }
      }
      .content-footer {
        margin-top: 28px;
        margin-bottom: 100px;
        display: flex;
        justify-content: space-between;

        .left-buttons {
          display: flex;
          .footer-button {
            width: 44px;
            height: 44px;
            opacity: 0.5;
          }
        }
        .right-buttons {
          .footer-button {
            width: 90px;
            height: 44px;
          }
        }
        .footer-button {
          display: flex;
          justify-content: center;
          align-items: center;
          border: solid 1px $black;
          font-family: NanumSquareB;
          font-size: 18px;
          line-height: 1.56;
          color: $black;
          cursor: pointer;
        }
      }
    }
  }
}
