@import '../../App.scss';

.notification-detail {
  .content {
    display: flex;
    justify-content: center;
    .container {
      width: 80%;
      max-width: 1280px;
      margin-top: 100px;
      .header {
        font-family: NanumSquareR;
        font-size: 18px;
        line-height: 1.56;
        display: flex;
        justify-content: space-between;
        border-top: 2px solid $black;
        border-bottom: 1px solid hsla(0, 0, 0, 0.15);
        padding: 16px 0;
        .title {
          font-family: NanumSquareB;
        }
      }
      .detail {
        padding: 62px 80px;
        font-family: NanumSquareR;
        font-size: 16px;
        line-height: 1.75;
        border-bottom: 1px solid hsla(0, 0, 0, 0.15);
      }
      .bottom-button {
        display: flex;
        justify-content: end;
        margin-top: 28px;
        margin-bottom: 100px;
        .button {
          font-family: NanumSquareB;
        }
      }
    }
  }
  
  @media (max-width: 800px) {
    .content {
      .container {
        margin-top: 36px;
        width: 90%;
        .detail {
          padding: 36px 0%;
        }
      }
    }
  }
}
