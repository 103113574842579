@import '../../App.scss';
.safe-area {
  height: 80px;
}

.header {
  height: 80px;
  width: 100vw;
  // position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  z-index: 1;
  border-bottom: rgba(0, 0, 0, 0.55) 1px solid;
  a {
    color: $white;
    &:hover {
      color: black;
    }
  }

  .contents {
    width: 80%;
    display: flex;
    justify-content: space-between;
    .logo {
      font-family: AirbnbCereal-Bold;
      img {
        width: 114px;
      }
      a {
        display: inline-block;
      }
      .mobile-menu {
        display: none;
      }
    }
    .menus {
      display: flex;
      font-family: AirbnbCereal-Medium;
      font-size: 15px;
      font-weight: bold;
      .munus-logo {
        display: none;
      }
      .menu {
        margin: 0 25px;
        .sub-menus {
          display: none;
        }
      }
      .close-button {
        display: none;
      }
    }

    @media screen and (max-width: 680px) {
      .menus {
        display: none;
      }
    }
  }

  &.open {
    background-color: $red;
    min-height: 440px;
    max-height: 500px;
    position: absolute;
    padding-top: 133px;
    padding-bottom: 72px;
    animation: show 0.3s forwards;

    .logo {
      position: absolute;
      top: 28px;
    }
    .menus {
      justify-content: space-evenly;
      width: 100%;
      .munus-logo {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
      }
      .menu {
        width: 20%;
        .main-menu {
          border-bottom: 1px solid rgba(255, 255, 255, 0.5);
          padding-bottom: 10px;
          font-family: AirbnbCereal-Bold;
          font-size: 20px;
          font-weight: bold;
        }
        .sub-menus {
          display: block;
          margin-top: 48px;
          font-family: NanumSquareB;
          font-size: 16px;
          .sub-menu {
            margin-top: 26px;
          }
        }
      }
      .close-button {
        display: block;
        position: absolute;
        top: 62px;
        right: 91px;
        cursor: pointer;
        width: 30px;
        height: 30px;
        &:after {
          content: '\00d7';
          font-family: NanumSquareL;
          font-size: 30px;
        }
      }
    }
  }
}
