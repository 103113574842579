@import '../../App.scss';

.insight {
  .content {
    display: flex;
    justify-content: center;
    .container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      max-width: 1400px;
      margin: 100px 20px;
      @media screen and (min-width: calc(393px + 37px * 2)) {
        width: calc(393px);
      }
      @media screen and (min-width: calc(393px * 2 + 37px * 2)) {
        width: calc(393px * 2);
      }
      @media screen and (min-width: calc(393px * 3 + 37px * 2)) {
        width: calc(393px * 3);
      }
      // @media screen and (min-width: calc($card-width * 2 + $card-margin * 2)) {
      //   width: calc($card-width * 2);
      // }
      // @media screen and (min-width: calc($card-width * 3 + $card-margin * 2)) {
      //   width: calc($card-width * 3);
      // }

      .card {
        width: 319px;
        height: 490px;
        margin: 57px 37px;
        overflow: hidden;
        float: left;

        img {
          width: 100%;
        }
        .card-container {
          margin: 29px 0;
          justify-content: start;
          h1 {
            font-family: NanumSquareEB;
            font-size: 22px;
            line-height: 1.45;
            color: #000;
          }
          .card-content {
            margin-top: 24px;
            font-family: NanumSquareR;
            opacity: 0.6;
            font-size: 16px;
            line-height: 1.75;
            color: #000;
          }
        }
      }
    }
  }
}
