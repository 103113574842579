@font-face {
  font-family: 'NanumSquareB';
  font-display: fallback;

  src: url('./NanumSquareB.ttf') format('truetype'),
    url('./NanumSquareB.woff') format('woff'),
    url('./NanumSquareB.eot?iefix') format('embedded-opentype');
}
@font-face {
  font-family: 'NanumSquareEB';
  font-display: fallback;

  src: url('./NanumSquareEB.ttf') format('truetype'),
    url('./NanumSquareEB.woff') format('woff'),
    url('./NanumSquareEB.eot?iefix') format('embedded-opentype');
}
@font-face {
  font-family: 'NanumSquareL';
  font-display: fallback;

  src: url('./NanumSquareL.ttf') format('truetype'),
    url('./NanumSquareL.woff') format('woff'),
    url('./NanumSquareL.eot?iefix') format('embedded-opentype');
}
@font-face {
  font-family: 'NanumSquareR';
  font-display: fallback;

  src: url('./NanumSquareR.ttf') format('truetype'),
    url('./NanumSquareR.woff') format('woff'),
    url('./NanumSquareR.eot?iefix') format('embedded-opentype');
}
@font-face {
  font-family: 'AirbnbCereal-Bold';
  font-display: fallback;

  src: url('./AirbnbCereal-Bold.ttf') format('truetype'),
    url('./AirbnbCereal-Bold.otf') format('opentype'),
    url('./AirbnbCereal-Bold.woff') format('woff'),
    url('./AirbnbCereal-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'AirbnbCereal-ExtraBold';
  font-display: fallback;

  src: url('./AirbnbCereal-ExtraBold.ttf') format('truetype'),
    url('./AirbnbCereal-ExtraBold.otf') format('opentype'),
    url('./AirbnbCereal-ExtraBold.woff') format('woff'),
    url('./AirbnbCereal-ExtraBold.woff2') format('woff2');
}
@font-face {
  font-family: 'AirbnbCereal-Medium';
  font-display: fallback;

  src: url('./AirbnbCereal-Medium.ttf') format('truetype'),
    url('./AirbnbCereal-Medium.otf') format('opentype'),
    url('./AirbnbCereal-Medium.woff') format('woff'),
    url('./AirbnbCereal-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'THEFACESHOP';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/InkLipquid.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}
