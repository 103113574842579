@import '../../App.scss';

.contact {
  .content {
    background-color: $grey;
    padding-top: 100px;
    padding-bottom: 160px;
    .container {
      display: flex;
      justify-content: center;
      .left {
        width: 313px;
        margin-right: 83px;
        h1 {
          font-family: NanumSquareEB;
          font-size: 36px;
          line-height: 1.67;
        }
        img {
          margin-top: 17px;
        }
      }
      .right {
        margin-left: 83px;
        width: 624px;
        padding: 40px 72px 64px;
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        font-family: NanumSquareB;
        h2 {
          font-size: 16px;
          line-height: 1.75;
          margin-top: 42px;
        }
        input[type='text'] {
          font-size: 16px;
          width: 100%;
          border: none;
          border-bottom: 1px solid rgba(0, 0, 0, 0.3);
          height: 35px;
          margin-top: 5px;
          font-family: NanumSquareR;
          font-size: 18px;
          line-height: 1.56;
        }
        input[type='file'] {
          margin-top: 11px;
        }
        .input-buttom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 59px;
          .personal-information-check {
            font-family: NanumSquareB;
            font-size: 18px;
            line-height: 1.56;
            display: flex;
            align-items: center;
            input[type='checkbox'] {
              margin-right: 12px;
              width: 24px;
              height: 24px;
            }
          }
          .button {
            margin-left: auto;
            background-color: $red;
            width: 152px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
          }
        }
      }
    }
  }
  .location {
    background-color: $black3;
    padding-top: 94px;
    padding-bottom: 116px;
    .container {
      h1,
      h3 {
        color: $white;
      }
      .right {
        background-color: transparent;
        box-shadow: none;
        padding: 0;
        h2 {
          font-family: AirbnbCerealApp-Medium;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.75;
          color: $red;
          margin-top: 0;
          padding-top: 20px;
          border-top: 1px solid rgba(255, 255, 255, 0.24);
        }
        h3 {
          padding-bottom: 14px;
          font-family: NanumSquareR;
          font-size: 19px;
          line-height: 1.47;
        }
        :nth-last-child(1) {
          border-bottom: 1px solid rgba(255, 255, 255, 0.24);
        }
      }
    }
    .map {
      display: flex;
      justify-content: center;
      margin-top: 54px;
      max-height: 80vh;
    }
  }

  @media (max-height: 899px), (max-width: 1199px) {
    .content {
      padding-top: 30px;
      padding-bottom: 0;
      .container {
        display: block;
        width: 100vw;
        .left {
          margin: 0 auto;
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 80vw;
          h1 {
            font-size: 25px;
          }
          img {
            margin-top: 0;
          }
        }
        .right {
          margin: 30px auto;
          width: 90vw;
          padding: 20px;
          box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
          background-color: #fff;
          font-family: NanumSquareB;
          h2 {
            font-size: 16px;
            line-height: 1.75;
            margin-top: 42px;
          }
          input[type='text'] {
            font-size: 16px;
            width: 100%;
            border: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.3);
            height: 35px;
            margin-top: 5px;
            font-family: NanumSquareR;
            font-size: 18px;
            line-height: 1.56;
          }
          input[type='file'] {
            margin-top: 11px;
          }
          .personal-information-check {
            font-family: NanumSquareB;
            font-size: 18px;
            line-height: 1.56;
            display: flex;
            align-items: center;
            input[type='checkbox'] {
              margin-right: 12px;
              width: 24px;
              height: 24px;
            }
            .button {
              margin-left: auto;
              background-color: $red;
              width: 152px;
              height: 48px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: $white;
            }
          }
        }
      }
    }
    .location {
      background-color: $black3;
      padding-top: 94px;
      padding-bottom: 116px;
      .container {
        h1,
        h3 {
          color: $white;
        }
        .right {
          background-color: transparent;
          box-shadow: none;
          padding: 0;
          h2 {
            font-family: AirbnbCerealApp-Medium;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.75;
            color: $red;
            margin-top: 0;
            padding-top: 20px;
            border-top: 1px solid rgba(255, 255, 255, 0.24);
          }
          h3 {
            padding-bottom: 14px;
            font-family: NanumSquareR;
            font-size: 19px;
            line-height: 1.47;
          }
          :nth-last-child(1) {
            border-bottom: 1px solid rgba(255, 255, 255, 0.24);
          }
        }
      }
      .map {
        display: flex;
        justify-content: center;
        margin-top: 54px;
      }
    }
  }
}
