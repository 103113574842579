$red: #ed1c24;
$black: #000;
$black2: #1c1308;
$black3: #222222;
$white: #ffffff;
$white2: #f6f6f6;
$white3: #d8d8d8;
$grey: #f7f7f7;
$riseupAnimation: 1s;
$fadeinAnimation: 2s;

.red {
  color: $red;
}

.clearfix::after {
  content: '';
  clear: both;
  display: table;
}

#root {
  overflow: hidden;
}

@keyframes typing {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    top: 1;
  }
}

@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    top: 0;
  }
}

@keyframes blink {
  0% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

.blinking {
  -webkit-animation: blink 0.7s ease-in-out infinite alternate;
  -moz-animation: blink 0.7s ease-in-out infinite alternate;
  animation: blink 0.7s ease-in-out infinite alternate;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.container {
  .swiper-button-next,
  .swiper-button-prev {
    color: #646464;
    width: 10vw; 
  }
}
// toastui-editor view를 사용할 경우 기본 글꼴 설정 
.toastui-editor-contents {
  font-family: NanumSquareR !important;
}
