@import '../../App.scss';

.why-planissue {
  background-color: $white2;
  min-height: 700px;
  color: $black;
  h1.top {
    font-family: AirbnbCereal-ExtraBold;
    font-size: 50px;
    font-weight: 800;
    color: $black;
    height: 80px;
    overflow: hidden;
    .top-content {
      margin-top: 80px;
      &.rise-up {
        animation: rise-up $riseupAnimation forwards;
      }
    }
    @keyframes rise-up {
      from {
        margin-top: 80px;
      }
      to {
        margin-top: 0px;
      }
    }
  }
  h3 {
    color: $black;
  }
  .middle {
    margin-top: 12px;
    font-family: NanumSquareR;
    font-size: 20px;
    line-height: 1;
    opacity: 0;
    &.fadein {
      animation: fadein $fadeinAnimation forwards;
      animation-delay: 0.5s;
    }
  }
  .bottom {
    margin-top: 37px;
    font-family: THEFACESHOP;
    font-size: 40px;
    h2 {
      color: $red;
      white-space: nowrap;
      overflow: hidden;
      margin: 0 auto;
      width: 0;
      &.typing {
        animation: typing 2s steps(40, end) forwards;
        animation-delay: 1s;
      }
    }
  }
  .cards {
    display: flex;
    justify-content: space-between;
    .card {
      width: 340px;
      // height: 380px;
      margin: 129px 0 0 25px;
      padding: 56px 20px;
      border-radius: 4px;
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
      opacity: 0;

      &.card1.fadein {
        animation: fadein $fadeinAnimation forwards;
        animation-delay: 1.5s;
      }

      &.card2.fadein {
        animation: fadein $fadeinAnimation forwards;
        animation-delay: 2s;
      }

      &.card3.fadein {
        animation: fadein $fadeinAnimation forwards;
        animation-delay: 2.5s;
      }

      h1 {
        font-family: NanumSquareEB;
        font-size: 26px;
        color: $red;
        margin-top: 30px;
      }
      p {
        margin-top: 18px;
        opacity: 0.8;
        font-family: NanumSquareR;
        font-size: 15px;
        line-height: 1.73;
        letter-spacing: -0.5px;
      }
    }
  }

  @media (max-height: 899px), (max-width: 1120px) {
    width: 100%;
    height: auto;
    // overflow: hidden;
    .middle {
      display: none;
    }
    h1.top {
      font-size: 10vmin;
      height: 14vmin;
      .top-content {
        margin-top: 14vmin;
        &.rise-up {
          animation: rise-up $riseupAnimation forwards;
        }
      }
      @keyframes rise-up {
        from {
          margin-top: 14vmin;
        }
        to {
          margin-top: 0px;
        }
      }
    }
    .bottom {
      margin-top: 0;
      h2 {
        font-size: 7vmin;
        span {
          display: block;
        }
        &.typing {
          animation: typing 1s steps(40, end) forwards;
          animation-delay: 0.5s;
        }
      }
    }
    .cards {
      width: 99vw;
      margin-top: 30px;
      // overflow: hidden;
      opacity: 1;
      .card {
        // opacity: 1;
        // width: 80vw;
        // width: 340px;
        // height: 380px;
        max-width: 28%;
        height: 280px;
        margin: 0 auto;
        margin-top: 10px;

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 20px;

        &.card1.fadein {
          animation: fadein $fadeinAnimation forwards;
          animation-delay: 1s;
        }

        &.card2.fadein {
          animation: fadein $fadeinAnimation forwards;
          animation-delay: 1.5s;
        }

        &.card3.fadein {
          animation: fadein $fadeinAnimation forwards;
          animation-delay: 2s;
        }
        .card-content {
        
          width: 100%;
          h1 {
            font-size: 5vmin;
            color: $black;
            span {
              display: block;
            }
          }
          .content {
            display: none;
          }
        }
      }
    }
  }
  @media (max-width: 666px) {
    .cards {
      display: block;
      width: 100vw;
      .card {
        max-width: 80vw;
        height: auto;

        .card-content {
          display: flex;
          justify-content: space-around;
          align-items: center;

          .icon {
            svg {
              height: 20vmin;
              width: 20vmin;
            }
          }

          h1 {
            margin-top: 0;
            span {
              display: block;
            }
          }
        }
      }
    }
  }

  @media (max-height: 666px) {
    h1.top {
      .top-content {
        margin-top: 0px;
      }
    }
    .middle {
      opacity: 1;
    }
    .bottom {
      h2 {
        width: 100%;
      }
    }
    .cards {
      .card {
        opacity: 1;
      }
    }
  }
}
