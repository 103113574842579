@import '../../App.scss';

.portfolio {
  .content {
    text-align: center;
    padding: 0 10px;
    .menus {
      display: flex;
      justify-content: center;
      margin: 100px 20px;
      // max-width: 1280px;
      // min-width: 500px;
      .menu {
        width: 280px;
        border: solid 1px #000;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: NanumSquareR;
        font-size: 20px;
        line-height: 1.25;
        &.active {
          background-color: #000;
          color: $white;
        }
      }
    }
    h1 {
      margin-top: 86px;
      font-family: NanumSquareEB;
      font-size: 36px;
      line-height: 0.94;
    }
    .line {
      width: 100px;
      height: 2px;
      background-color: #000;
      margin: 51px auto 0;
    }
    .discription {
      margin-top: 47px;
      font-family: NanumSquareR;
      font-size: 20px;
      line-height: 1.7;
    }
    .container {
      display: flex;
      justify-content: center;

      .cards {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 1280px;
        margin: 72px 20px 142px;

        @media screen and (min-width: calc(382px + 20px * 2)) {
          width: calc(382px);
        }
        @media screen and (min-width: calc(382px * 2 + 20px * 2)) {
          width: calc(382px * 2);
        }
        @media screen and (min-width: calc(382px * 3 + 20px * 2)) {
          width: calc(382px * 3);
        }

        .card {
          width: 340px;
          margin: 0 21px 88px;
          border-radius: 10px;
          box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);
          height: 540px;
          background-color: #fff;
          overflow: hidden;
          float: left;

          &:hover {
            transform: scale(1.05, 1.05);
            transition-duration: 0.3s;
          }

          .image {
            height: 227px;
            background-size: cover;
          }
          .content {
            text-align: left;
            padding: 32px 25px;
            h2 {
              font-family: NanumSquareR;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $red;
            }
            h1 {
              font-family: NanumSquareEB;
              font-size: 22px;
              line-height: 1.45;
              margin-top: 10px;
              height: 64px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .discription {
              opacity: 0.6;
              font-family: NanumSquareR;
              font-size: 16px;
              line-height: 1.75;
              margin-top: 24px;
              height: 77px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
            .date {
              opacity: 0.6;
              font-family: NanumSquareR;
              font-size: 16px;
              line-height: 1.75;
              margin-top: 28px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
          }
        }
      }
    }
    a {
      &:link {
        color: $black;
      }
      &:visited {
        color: $black;
      }
      &:hover {
        color: $black;
      }
    }
  }
  @media (max-width: 420px) {
    .content {
      .menus {
        .menu {
          font-size: 4vw;
          span {
            display: block;
          }
        }
      }
    }
  }
}
