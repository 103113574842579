@import '../../App.scss';

.notification {
  .content {
    display: flex;
    justify-content: center;
    .container {
      width: 80%;
      max-width: 1280px;
      .search {
        --antd-primary-color: $red;
        display: flex;
        justify-content: flex-end;
        margin-top: 100px;
        text-align: end;

        // div,
        // div:hover,
        // div:focus,
        // div:active,
        // input:hover,
        // input:focus,
        // button:hover,
        // button:focus {
        //   border-color: $black;
        //   color: $black;
        //   box-shadow: none;
        // }

        font-size: 18px;
        line-height: 1.56;
        .search-select {
          width: 140px;
          * {
            text-align: start;
          }
          > div {
            padding: 0 18px;
          }
          // height: 47px;
          //   font-size: 18px;
          //   line-height: 1.56;
          //   color: #000;
        }
        .search-input {
          margin-left: 12px;
          width: 220px;
          // height: 47px;
          // border: none;
        }
        .search-button {
          width: 90px;
          background-color: $black;
          color: $white;
          // height: 47px;
        }
      }
      .table {
        border-top: 2px solid $black;
        margin-top: 28px;
        tbody tr {
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 460px) {
    .content {
      .container {
        width: 95%;
      }
    }
  }
}
