@import '../../App.scss';

.business-area {
  background-color: $white;
  min-height: 667px;
  color: $black;
  word-break: keep-all;
  h1.top {
    font-family: AirbnbCereal-ExtraBold;
    font-size: 50px;
    font-weight: 800;
    color: $black;
    height: 80px;
    overflow: hidden;
    .top-content {
      margin-top: 80px;
      &.rise-up {
        animation: rise-up $riseupAnimation forwards;
      }
    }
    @keyframes rise-up {
      from {
        margin-top: 80px;
      }
      to {
        margin-top: 0px;
      }
    }
  }
  .middle {
    margin-top: 16px;
    font-family: NanumSquareR;
    font-size: 20px;
    line-height: 0.7;
    opacity: 0;
    &.fadein {
      animation: fadein $fadeinAnimation forwards;
      animation-delay: 0.5s;
    }
  }
  .cards {
    // display: flex;
    // justify-content: space-between;
    margin-top: 92px;
    width: 100vw;
    overflow: hidden;
    opacity: 0;

    &.fadein {
      animation: fadein $fadeinAnimation forwards;
      animation-delay: 1s;
    }
    .card {
      width: 680px;
      // max-width: 80vw;
      height: 344px;
      // margin: 0 32px;
      // margin: 92px 32px 0 30px;
      box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.15);
      background-color: $white;
      display: flex;

      .img-area {
        width: 280px;
        height: 344px;
      }
      .contents {
        width: 400px;
        padding: 58px 48px 48px;
        text-align: left;

        h1 {
          font-family: NanumSquareEB;
          font-size: 24px;
          line-height: 1.83;
          color: #000;
        }
        p {
          margin-top: 12px;
          margin-bottom: 58px;
          font-family: NanumSquareL;
          font-size: 16px;
          line-height: 1.88;
        }
        a {
          border-color: $red;
          color: $red;
          font-family: NanumSquareB;
          font-size: 16px;
          line-height: 1.75;
          border: 1px $red solid;
          padding: 7px 25px;
        }
      }
    }
  }
  .logo-big-gray {
    width: 80%;
    opacity: 0.05;
    position: absolute;
    bottom: 0;
    left: 100vw;

    animation: slide-left linear 15s infinite;
    @keyframes slide-left {
      0% {
        // transform: translateX(100);
      }
      100% {
        // transform: translateX(calc(0px - 80vw));
        left: -80vw;
      }
    }
  }

  @media (max-height: 899px), (max-width: 1199px) {
    .middle {
      display: none;
    }
    @media (orientation: portrait) {
      h1.top {
        font-size: 10vw;
        height: 14vw;

        @keyframes rise-up {
          from {
            margin-top: 14vw;
          }
          to {
            margin-top: 0px;
          }
        }
      }
      .cards {
        &.fadein {
          animation: fadein $fadeinAnimation forwards;
          animation-delay: 0.5s;
        }
        margin-top: 20px;
        .card {
          width: 80vw;
          height: auto;
          display: block;
          .img-area {
            display: none;
          }
          .contents {
            // position: absolute;
            // a {
            //   position: absolute;
            //   bottom: 10px;
            // }
            p {
              display: none;
            }
            width: 100%;
            padding: 30px;
            margin: 0 auto;
          }
          .responsive-contents-content {
            display: block !important;
            font-size: 4vw;
            padding: 5px;
          }
          .responsive-contents {
            display: block !important;
            position: relative;
            width: 100%;
            padding: 30px;
            height: 30vh;

            &:after {
              content: '';
              opacity: 0;
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
              background-color: $white;
            }
          }
        }
      }
      .logo-big-gray {
        width: 80vh;
        animation: slide-left linear 15s infinite;
        @keyframes slide-left {
          0% {
            // transform: translateX(100);
          }
          100% {
            // transform: translateX(calc(0px - 80vw));
            left: -80vh;
          }
        }
      }
    }
  }

  @media (max-height: 666px) {
    h1.top {
      .top-content {
        margin-top: 0;
      }
    }
    .middle {
      opacity: 1;
    }
    .cards {
      opacity: 1;
    }
  }
}
