@import '../../App.scss';

.promotion {
  height: 240px;
  position: relative;
  background-color: $red;
  color: $white;
  text-align: left;
  // display: flex;
  // justify-content: center;

  .content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 20px;
    // width: 80%;
    // max-width: 1200px;
    min-width: 900px;
    h1 {
      // float: left;
      width: 457px;
      font-family: NanumSquareEB;
      font-size: 36px;
      line-height: 1.44;
      margin: 68px 0;
      color: $white;
    }
    div {
      // float: right;
      width: 260px;

      .download {
        width: 260px;
        height: 52px;
        margin: 94px 0;
        // padding: 15px 30px 15px 32px;
        border-radius: 26px;
        border: solid 1px #e6e6e6;
        background-color: $red;
        a {
          font-family: NanumSquareB;
          font-size: 20px;
          color: #fff;
        }
      }
    }
  }
  @media (max-width: 900px) {
    height: auto;
    padding: 20px 0;
    .content {
      min-width: 100vw;
      width: 100vw;
      display: block;
      margin: 0;
      h1 {
        font-size: 5vw;
        margin: 0 auto;
        width: auto;
        text-align: center;
      }
      div {
        margin-top: 20px;
        text-align: center;
        width: 100vw;
        .download {
          margin: 0 auto;
          display: block;
        }
      }
    }
  }
}
