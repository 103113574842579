@import '../../App.scss';

.header {
  background-image: url('/static/images/sub-img-1.png');
}

.talent-welfare {
  .contents {
    text-align: center;
    margin: 0 5%;
    h1 {
      margin-top: 100px;
      font-family: NanumSquareEB;
      font-size: 36px;
      line-height: 1.67;
    }
    .talents {
      margin-top: 84px;
      .talent {
        display: flex;
        justify-content: center;

        .container {
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 1px solid rgba(0, 0, 0, 0.3);
          padding: 20px 0;
          .left {
            margin: 0 48px 0 0;
            width: 45%;
            img {
              width: 100%;
              max-width: 464px;
            }
          }
          .right {
            display: flex;
            align-items: center;
            width: 47%;
            // margin: 0 48px 0 0;
            text-align: left;
            h2 {
              font-family: NanumSquareEB;
              font-size: 28px;
              line-height: 1;
              .seq {
                color: $red;
              }
            }
            .discription {
              display: flex;
              align-items: center;
            }
            .operation {
              font-family: THEFACESHOP;
              font-size: 50px;
              line-height: 0.72;
              color: $red;
              margin-right: 11.5px;
            }
            .content {
              margin-top: 25px;
              font-family: NanumSquareR;
              font-size: 16px;
              line-height: 2.25;
            }
          }
        }

        @media screen and (max-width: 712px) {
          .container {
            display: block;
            .left {
              margin: 0 auto;
              width: 100%;
            }
            .right {
              margin: 0 auto;
              margin-top: 40px;
              width: 100%;
            }
          }
        }
      }
      > :nth-last-child(1) {
        .container {
          border: none;
        }
      }
    }
  }
  .recruitment-banner {
    margin-top: 140px;
    background-image: url('/static/images/img-banner@3x.png');
    background-size: cover;
    padding: 70px 0;
    // height: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .left {
      margin: 0 20px;
      h2 {
        color: $white;
        font-family: NanumSquareR;
        font-size: 20px;
        line-height: 1.7;
      }
      h3 {
        color: $red;
        font-family: NanumSquareEB;
        font-size: 18px;
        line-height: 1.67;
      }
    }
    .right {
      margin: 0 20px;
      .button {
        width: 200px;
        height: 54px;
        border-radius: 27px;
        border: solid 1px #fff;
        font-family: NanumSquareB;
        font-size: 16px;
        line-height: 1.63;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    @media screen and (max-width: 500px) {
      display: block;
      .right {
        margin-top: 20px;
      }
    }
  }
  .welfares {
    display: flex;
    justify-content: center;
    padding-top: 108px;
    padding-bottom: 100px;
    background-color: rgba(216, 216, 216, 0.3);
    .container {
      max-width: 1200px;
      h2 {
        margin: 0 40px;
        font-family: NanumSquareEB;
        font-size: 30px;
        line-height: 1.67;
      }
      .welfare-container {
        margin: 61px 20px 0;
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        .welfare {
          width: 340px;
          // height: 200px;
          margin: 26px 21px;
          padding: 32px 32px 21px;
          box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
          background-color: #fff;
          &:hover {
            transform: scale(1.08, 1.08);
            transition-duration: 0.3s;
          }
          .title {
            margin-top: 16px;
            font-family: NanumSquareEB;
            font-size: 22px;
            line-height: 1.45;
          }
          .content {
            margin-top: 13px;
            opacity: 0.6;
            font-family: NanumSquareR;
            font-size: 16px;
            line-height: 1.75;
          }
          .red {
            color: $red;
          }
        }
      }
    }
  }
}
